<template>
    <div class="field field-phone-number"
        :class="{
            'is-invalid': !!errorMessage,
            'is-readonly': readonly,
            'field-underlined': underlined
        }">
        <label v-if="label" class="form-label" :for="idAttr">{{ label }}<span v-if="mandatory" class="text-danger ms-1">*</span></label>
        <input type="tel" 
            class="form-control"
            :class="{ 'is-invalid': !!errorMessage }"
            :id="idAttr"
            ref="inputEl"
            :disabled="disabled"
            :readonly="readonly"
            :autocomplete="autocomplete"
            @input="onInput"
            @blur="handleBlur"
            @countrychange="onCountryChange">
        <p v-show="errorMessage" class="caption text-danger mb-0 error-message">{{ errorMessage }}</p>
    </div>
</template>

<script lang="ts" setup>
import 'intl-tel-input/build/css/intlTelInput.css';
import { PropType, ref, onMounted, nextTick, watch, computed } from 'vue';
import IntlTelInput from 'intl-tel-input';
import { useField } from 'vee-validate';
import kebabCase from 'lodash/kebabCase';

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    id: String,
    label: String,
    readonly: Boolean,
    disabled: Boolean,
    autocomplete: String,
    preferredCountries: {
        type: Object as PropType<string[]>
    },
    options: {
        type: Object as PropType<IntlTelInput.Options>,
        default: () => ({})
    },
    underlined: Boolean,
    mandatory: Boolean,
})

const {
    value: inputValue,
    errorMessage,
    handleBlur,
    setValue
} = useField<string>(
    props.name,
    undefined
);

const inputEl = ref<HTMLInputElement|null>(null);
let instance: IntlTelInput.Plugin;

const idAttr = computed(() => props.id ?? 'tel-' + kebabCase(props.name));
const isInit = ref(true);

onMounted(() => {
    let pluginOptions: IntlTelInput.Options = {
        utilsScript: '/assets/vendor/js/intl-tel-input/utils.js',
        separateDialCode: true,
        customContainer: 'form-control-wrapper',
        ...props.options
    }
    if (props.preferredCountries) {
        pluginOptions.preferredCountries = props.preferredCountries;
    }
    if (inputEl.value) {
        instance = IntlTelInput(inputEl.value, pluginOptions)

        instance.promise.then(async x => {
            if (inputValue.value) {
                instance.setNumber(inputValue.value);
            } else {
                // If there's no preferred countries AND
                // the field's value is empty, let's guess the country
                if (props.preferredCountries && props.preferredCountries.length > 0) return;
                const country = await guessCountry()
                if (!country) return;
                instance.setCountry(country.abbr.toLowerCase())
            }
            isInit.value = false;
        })
    }
})

const onInput = () => {
    if (!instance) return;
    setValue(instance.getNumber());
}

const onCountryChange = () => {
    if (isInit.value) return;
    nextTick(() => {
        if (!instance) return;
        setValue(instance.getNumber());
    })
}

const guessCountry = async () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const data = (await import('./countries-timezones-map')).default;

    const countryData = Object.entries(data).find(([countryCode, countryData]) => {
        return countryData.zones.find(x => x === timezone);
    })

    if (!countryData) return;

    return countryData[1];
}

watch(
    inputValue,
    (newValue) => {
        if (!newValue) {
            instance.setNumber('');
        }
    }
)

</script>

<style lang="scss">

.iti {
    width: 100%;

    &__country-list {
        z-index: $zindex-popover;
    }

    &__selected-flag {
        border-top-left-radius: $input-border-radius;
        border-bottom-left-radius: $input-border-radius;
    }

    &__flag-container {
        &:focus-within + .form-control {
            box-shadow: $input-btn-focus-box-shadow;
            outline-color: $primary;
        }
    }
}

.field-phone-number.field-underlined {
    .iti__selected-flag {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    
    .iti__flag-container:focus-within + .form-control {
        box-shadow: none;
    }

    &.is-readonly {
        .iti__selected-flag {
            background-color: initial;

            &:hover {
                background-color: initial;
            }
        }
    }
}
</style>